import React, { useEffect, useState } from "react";
import Slider from "react-slick";

import top_rigt_arrow from "assets/images/header.svg";
import Modal from "react-bootstrap/Modal";

import block_01_01 from "assets/images/block_01_01.png";
import block_01_02 from "assets/images/block_01_02.png";
import block_01_03 from "assets/images/block_01_03.png";
import block_01_01_1 from "assets/images/block_01_01_1.png";
import block_01_02_2 from "assets/images/block_01_02_2.png";

import block_02 from "assets/images/block_02_bg.png";

import carousel_01 from "assets/images/carousel_01.png";
import carousel_02 from "assets/images/carousel_02.png";
import carousel_03 from "assets/images/carousel_03.png";
import carousel_04 from "assets/images/carousel_04.png";
import carousel_05 from "assets/images/carousel_05.png";
import carousel_06 from "assets/images/carousel_06.png";
import carousel_07 from "assets/images/carousel_07.png";

import block_04_01 from "assets/images/block_04_01.png";
import block_04_02 from "assets/images/block_04_02.png";
import block_04_03 from "assets/images/block_04_03.png";

import block_06_01 from "assets/images/block_06_01.png";
import block_06_02 from "assets/images/block_06_02.png";
import block_06_03 from "assets/images/block_06_03.png";
import block_06_04 from "assets/images/block_06_04.png";

import popup from "assets/images/popup.jpg";
import arrow_right from "assets/images/arrow_right.svg";
import ban from "assets/images/ban.svg";
import video from "assets/images/video.svg";
import arrow_down from "assets/images/arrow_down.svg";
import block_05_01 from "assets/images/block_05_img.png";
import header from "assets/images/header.svg";
import logo from "assets/images/logo2.svg";

import block_05 from "assets/images/block_05.png";


function App() {


  const [isOpen, setIsOpen] = useState(false);
  var settings = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 7,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  const block3Items = [
    carousel_01,
    carousel_02,
    carousel_03,
    carousel_04,
    carousel_05,
    carousel_06,
    carousel_07,
  ];
  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  function hendleClick(){
    alert('Coming soon!');
  }
  useEffect(() => {
    const lastOpened = localStorage.getItem('lastOpened');
    if (lastOpened) {
      const diff = Date.now() - Number(lastOpened);
      if (diff >= 10 * 60 * 1000) {
        openModal();
        localStorage.setItem('lastOpened', Date.now());
      } else {
        const timeout = setTimeout(openModal, 10 * 60 * 1000 - diff);
        return () => clearTimeout(timeout);
      }
    } else {
      openModal();
      localStorage.setItem('lastOpened', Date.now());
    }
  }, []);
  return (
    <>
      <div className="content-wrapper">
        <section className="block_01">
        <div className="container ">
          <div>
            <div className="block_01_top">
              <div className="subtitle">
                <span className="new_text">새로운</span> 브라우저,
                <span className="new_text">새로운</span> 기술
              </div>
              <div className="title">
                모바일과 pc에서 자유롭게, 오로라 브라우저에서 무료로 제공하는 <br /> 혁신적인 서비스를 느껴보세요.
              </div>
              <div className="subtitle second">
                Ads begone,Video Aplenty: Orora,your Gateway to seamless
                browsing
              </div>
              <div className="d-flex justify-content-center mb-4">
                <a href="https://dw7ubtm4238pg.cloudfront.net/OroraSetup.exe"  className="main-btn">다운로드 오로라 브라우저</a>
                <button onClick={hendleClick} className="main-btn-mobile ">
                다운로드 오로라 브라우저
            </button>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 ">
              <div className="block_01_card">
                <div className="block_01_card_img">
                  <div className="">
                    <img src={block_01_01} alt="" />
                    <div className="block_01_card_img_first">
                      <img src={block_01_01_1} alt="" />
                    </div>
                  </div>
                </div>
                <div>
                  <div className="title">광고 차단</div>
                  <div className="description">
                    빠른 속도와 광고 없는 환경에서 방해받지 않고 안전하게 다양한
                    컨텐츠를 이용하세요.
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="block_01_card">
                <div className="block_01_card_img">
                  <div className="relative">
                    <img src={block_01_02} alt="" />
                    <div className="block_01_card_img_first">
                      <img src={block_01_02_2} alt="" />
                    </div>
                  </div>
                </div>
                <div className="title">동영상 다운로드</div>
                <div className="description">
                  인기 웹사이트에서 고품질 비디오를 다운로드하세요 스트리밍
                  서비스도 미리 다운받아 끊김없는 시청과 wifi없는 환경에서도
                  자유롭게 시청하세요
                </div>
              </div>
            </div>
            <div className="col-md-12 ">
              <div className="block_01_card">
                <div className="title">https 차단우회 접속</div>
                <div className="description">
                향상된 보안 기능으로 안전하고 우회접속이 필요한 환경에서 자동 on/off 기능으로 <br /> 편리하게 웹사이트를 접속하세요
                </div>
                <div className="block_02_card">
                  <div className="block_01_card_img">
                    <img src={block_01_03} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </section>
       

      <div className="container block_02">
          <img src={block_02} alt="" />
          <div className="phone">

          </div>
        </div> 

        <div className="container-fluid block_03">
          <div className="title">
            <b>Over 100</b> companies trust us.
          </div>
          <Slider {...settings}>
            {block3Items.map((item, index) => (
              <div className="block_03_item" key={index}>
                <img src={item} alt="" />
              </div>
            ))}
          </Slider>
        </div>

        <div className="container block_04">
          <div className="title">
            광고 없는 환경 설정 끊김없는 비디오 <br />
            시청 빠른 속도의 동영상 다운로드
          </div>
          <div className="subtitle">Discover What Sets Us Apart</div>

          <div className="block_04_items">
            <div className="block_04_item first">
              <div className="row">
                <div className="col-md-6 text-center">
                  <img src={block_04_01} alt="" />
                </div>
                <div className="col-md-6">
                  <div className="block_grey">
                    <div className="block_04_item_card">
                      <div className="block_04_item_title">개인정보 보호</div>
                      <div className="block_04_item_description">
                        www.youtube.com
                      </div>
                      <div className="block_04_item_inner_card">
                        <div>
                          <img src={ban} alt="" />
                          27 이 페이지에서 광고 차단됨
                        </div>
                        <button className="block_04_item_inner_button">
                          이 사이트에서 끄기
                        </button>
                      </div>
                    </div>
                    <div className="title">
                      광고 차단 on/off 기능으로 <br />수 많은 광고 스트레스로
                      부터 해방되다
                    </div>
                    <div className="description">
                      팝업 광고, 배너 광고, 비디오 속 광고 등을 <br />
                      차단하여 페이지 로딩 속도는 빨라지고 <br />
                      배터리가 절약되어 초고속 웹 브라우징을 <br />
                      즐길 수 있습니다.
                    </div>

                    <button className="card_btn">Ads free</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="block_04_item second">
              <div className="row">
                <div className="col-md-6 text-center second_tag">
                  <img src={block_04_02} alt="" />
                </div>
                <div className="col-md-6 text-end  first_tag">
                  <div className="block_grey">
                    <div className="block_04_item_second_card">
                      <div className="title">보안 dns사용</div>
                      <div className="description">Status</div>
                      <div className="title">Connected</div>
                    </div>
                    <div className="title">
                      브라우저에 내장된 무료 보안 DNS사용으로 <br />
                      개인정보보호와 보안 수준을 개선하세요
                    </div>
                    <div className="description">
                      보안DNS 사용으로 온라인 활동을 보호하고 <br />
                      데이터를 안전하게 유지하며 불필요한 <br />
                      광고 활동추적 방지를 도와줍니다.
                    </div>
                    <button className="card_btn">Unlimited VPN</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="block_04_item third">
              <div className="row">
                <div className="col-md-6 text-center">
                  <img src={block_04_03} alt="" />
                </div>
                <div className="col-md-6">
                  <div className="block_grey">
                    <div className="block_04_item_third_cards">
                      <div className="d-flex gap-2 mb-2">
                        <div className="block_04_item_third_card w-fc">
                          <img src={video} alt="" />
                        </div>
                        <div className="block_04_item_third_card w-100">
                          다운로드 할 영상 <b>URL</b>을 입력하세요.
                        </div>
                      </div>

                      <div className="d-flex gap-2 mb-4">
                        <div className="block_04_item_third_card justify-content-center w-100">
                          Video
                        </div>
                        <div className="block_04_item_third_card justify-content-center w-100">
                          Audio
                        </div>
                        <div className="block_04_item_third_card justify-content-center w-100">
                          Full HD
                          <img src={arrow_down} alt="" />
                        </div>
                      </div>
                    </div>

                    <div className="title">
                      다양한 컨텐츠 스트리밍 동영상을 손쉽게 <br />
                      다운로드 하다
                    </div>
                    <div className="description">
                      wi-fi 없는 환경에서 보고 싶은 동영상 등을 <br />
                      오로라 브라우저의 효과적인 다운로드 <br />
                      기능을 활용하여 원하는 미디어, 음악파일을 <br />
                      쉽게 즉시 다운로드 할 수 있습니다.
                    </div>
                    <button className="card_btn">Downloader</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid block_05">
          <div className="row">
            <div className="col-lg-6">
              <div className="text">
                모바일 오로라 브라우저도 만나보세요. <br />
                빠르고 안전한 모바일 웹브라우저를 다운로드 하세요
              </div>
              <button className="main-btn">다운로드 오로라 브라우저</button>
              <button onClick={hendleClick} className="main-btn-mobile ">
                다운로드 오로라 브라우저
            </button>
            </div>
            <div className="col-lg-6">
              <div className="text-center">
                <img src={block_05_01} alt="" className="block_05_01_img" />
              </div>
            </div>
          </div>
        </div>
        <div className="container block_06">
          <div className="row">
            <div className="col-lg-12">
              <div className="title">
                <b>ORORA</b>에 대해 알아보세요
              </div>
              <div className="block_06_boxs">
                <div className="block_06_box">
                  <img src={block_06_01} alt="" />
                  <div className="title_01">VPN</div>
                </div>
                <div className="block_06_box">
                  <img src={block_06_02} alt="" />
                  <div className="title_01">Ad block</div>
                </div>
                <div className="block_06_box">
                  <img src={block_06_03} alt="" />
                  <div className="title_01">Download</div>
                </div>
                <div className="block_06_box">
                  <img src={block_06_04} alt="" />
                  <div className="title_01">Privacy</div>
                </div>
                <div className="block_06_box">
                  <div className="title_01">Others</div>
                  <div className="text_01">
                    Browsing <br />
                    File manager <br />
                    Media Player <br />
                    Profile <br />
                    Appearance <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={isOpen}
        onHide={closeModal}
        className="auto_modal"
      >
        <div className="gift_window_modal_01_header_02 pb-0">
          <div></div>
          {/* <AiOutlineClose onClick={closeModal} className="gift_window_modal_01_header_close_icon" size={20} /> */}
        </div>
        <Modal.Body>
          <img src={popup} onClick={closeModal} alt="" />
        </Modal.Body>
        {/* <Modal.Footer className="gift_window_modal_01_footer justify-content-center">
          <a href="#notice-detail" className="gift_window_modal_btn_primary_02 text-center">
          read more
          </a>
        </Modal.Footer> */}
      </Modal>

    </>
  );
}

export default App;
